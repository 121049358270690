import { Borrower } from "../models/borrower-model";
import { WizardFlowContext } from "../models/wizard-flow-context.model";
import { WizardFlowDecisionContext } from "../models/wizard-flow-decision-context.model";
import { ContextType } from "../models/wizard/config/context-type.enum";

export class ContextExtractor {

  public static prepareDecisionContext(wizardFlowContext: WizardFlowContext): WizardFlowDecisionContext {
    // TODO: Do we really need this now? It seems like the decision context is the wizard flow context itself now!?
    const mortgage = this.extractContext(wizardFlowContext, ContextType.Mortgage);
    const mainBorrower = this.extractContext(wizardFlowContext, ContextType.MainBorrower);
    const borrowersThatMainBorrowerIsApplyingFor = this.extractContext(wizardFlowContext, ContextType.BorrowersThatMainBorrowerIsApplyingFor);
    const customerPreferences = this.extractContext(wizardFlowContext, ContextType.CustomerPreferences);
    let decisionContext = new WizardFlowDecisionContext();
    decisionContext.mortgage = mortgage;
    decisionContext.mainBorrower = mainBorrower;
    decisionContext.borrowersThatMainBorrowerIsApplyingFor = borrowersThatMainBorrowerIsApplyingFor;
    decisionContext.customerPreferences = customerPreferences;
    decisionContext.creditRunFailed = wizardFlowContext.creditRunFailed;
    decisionContext.skipCreditCheck = wizardFlowContext.skipCreditCheck;
    decisionContext.skipAutomatedCreditCheck = wizardFlowContext.skipAutomatedCreditCheck;
    decisionContext.thereAreBorrowerCharacteristics = wizardFlowContext.thereAreBorrowerCharacteristics;
    decisionContext.thereAreLoanCharacteristics = wizardFlowContext.thereAreLoanCharacteristics;
    decisionContext.hasSuccessfullyPulledExistingApplication = wizardFlowContext.hasSuccessfullyPulledExistingApplication;
    return decisionContext;
  }

  public static extractContext(wizardFlowContext: WizardFlowContext, contextType: ContextType): any {
    if (contextType == ContextType.Mortgage) {
      return wizardFlowContext.mortgage;
    } else if (contextType == ContextType.SubjectProperty) {
      return wizardFlowContext.mortgage.subjectProperty;
    } else if (contextType === ContextType.Extension) {
      return wizardFlowContext.mortgage.extension;
    } else if (contextType == ContextType.CustomerPreferences) {
      return wizardFlowContext.customerPreferences;
    } else if (contextType == ContextType.Borrowers) {
      return wizardFlowContext.mortgage.borrowers;
    } else if (contextType == ContextType.MainBorrower) {
      return wizardFlowContext.mortgage.borrowers[0];
    } else if (contextType == ContextType.WizardFlowContextItself) {
      return wizardFlowContext;
    } else if (contextType == ContextType.Liabilities) {
      return wizardFlowContext.mortgage.liabilities;
    } else if (contextType == ContextType.BorrowersThatMainBorrowerIsApplyingFor) {
      let borrowers: Borrower[] = [];
      wizardFlowContext.mortgage.borrowers.forEach(b => {
        const selected = wizardFlowContext.borrowerSettings.get(b.borrowerId);
        if (selected && selected === true) {
          borrowers.push(b);
        }
      });
      return borrowers;
    } else {
      return wizardFlowContext.mortgage;
    }
  }

}
