import { Component, Injector, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ConsentAuthorizationStep } from '../../../models/wizard/consent-authorization-step-model';
import { CreditService } from '../../../services/credit.service';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { Borrower } from '../../../models/borrower-model';
import { formatDate } from '@angular/common';
import { GenerateEConsentRequest } from '../../../models/generate-e-consent-request.model';

declare const $: any;

@Component({
  selector: 'demographics',
  templateUrl: 'consent-authorization-step.component.html'
})
export class ConsentAuthorizationStepComponent extends WizardStepComponentBase<ConsentAuthorizationStep> implements OnInit {

  consentToDoBusiness: boolean;

  showEConsentVerbiage: boolean;
  showCreditAuthVerbiage: boolean;

  creditCheckConsentVerbiage: string | undefined = "";
  eBusinessConsentVerbiage: string | undefined = "";

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  creditCheckConsentAlreadyGiven: boolean = false;

  private _eConsentGenerationEnabled: boolean = false;
  private _creditAuthGenerationEnabled: boolean;

  get isStepComplete(): boolean {
    return this.consentToDoBusiness && (!this.showCreditAuthVerbiage || (this.showCreditAuthVerbiage
      && this.currentBorrower.authorizedCreditCheck));
  }

  get canMoveToNextStep(): boolean {
    return this.isStepComplete;
  }

  constructor(private readonly _injector: Injector,
    private readonly _creditService: CreditService) {
    super(_injector);
    this.consentToDoBusiness = true;
    this.saveMortgageApplicationBeforeNextStep = true;
    const borrowers = this.inCoApplyFlow ? [this.currentBorrower] : this.mortgageApplication.borrowers;
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.creditCheckConsentAlreadyGiven = this.mainBorrower.authorizedCreditCheck;
      if (!this.mainBorrower.authorizedCreditCheck) {
        this.mainBorrower.authorizedCreditCheck = true; // So that the UI defaults to give consent
      }
      this.coBorrowers = borrowers.filter(b => b.borrowerId !== this.mainBorrower.borrowerId);
      this.coBorrowers.forEach(c => {
        c.willBeEdited = this.borrowerSetting(c);
      });
    }
  }

  ngOnInit() {
    this.startSpinner();
    this.mortgageApplicationService.getConfig(this.mortgageApplication.applicationId).pipe(
      finalize(() => {
        this.stopSpinner();
      })
    )
      .subscribe(response => {
        this.creditCheckConsentVerbiage = response.creditAuthText;
        this.eBusinessConsentVerbiage = response.eConsentText;
        this._eConsentGenerationEnabled = response.eConsentGenerationEnabled;
        this.showEConsentVerbiage = response.showEConsentVerbiage;
        this.showCreditAuthVerbiage = response.showCreditAuthVerbiage;
        this._creditAuthGenerationEnabled = response.creditAuthGenerationEnabled;
      })
  }

  onNextClicked() {

    const apiCalls: any[] = [];

    if (this._creditAuthGenerationEnabled && this.mainBorrower.authorizedCreditCheck) {

      const today = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
      this.mainBorrower.dateAuthorizedCreditCheck = today;
      this.mainBorrower.authorizationMethod = "Internet";

      this.coBorrowers.forEach(coBorrower => {
        if (this.borrowerSetting(coBorrower)) {
          coBorrower.authorizedCreditCheck = true;
          coBorrower.dateAuthorizedCreditCheck = today;
          coBorrower.authorizationMethod = "Internet";
        }
      });

      apiCalls.push(this._creditService.createConsentReport(this.mortgageApplication.applicationId, this.creditCheckConsentVerbiage));
    }

    if (this._eConsentGenerationEnabled) {
      apiCalls.push(this.mortgageApplicationService.generateEConsentDocument(this.mortgageApplication.applicationId,
        { eConsentText: this.eBusinessConsentVerbiage, borrowerId: this.mainBorrower.contactId }));
    }

    if (apiCalls.length > 0) {
      this.startSpinner();
      forkJoin(apiCalls).subscribe(
        (result) => {
          this.stopSpinner();
          super.onNextClicked();
        },
        (error) => {
          this.stopSpinner();
          super.onNextClicked();
        }
      );
    } else {
      super.onNextClicked();
    }
  }
}
