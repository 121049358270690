import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Interval } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { AuthenticationRequest } from '../models/auth/auth-request.model';
import { AuthenticationResponse, AuthenticationUserCompany } from '../models/auth/auth-response.model';
import { LoginStatus } from '../models/auth/login-status.enum';
import { AuthService } from '../services/auth.service';
import { Constants } from '../services/constants';
import { NavigationService } from '../services/navigation.service';
import { TimerService } from '../services/timer.service';
import { WizardFlowServiceBase } from '../services/wizard/wizard-flow-service.base';
import { PageUtils } from 'projects/shared/utils/page.utils';
import { Observer, Subscription, finalize } from 'rxjs';
import { EnumsService } from '../services/enums.service';
import Swal from 'sweetalert2';
import { getErrorMessageOrDefault } from 'projects/shared/utils/error.utils';

export enum PortalScope {
  Borrower = "Borrower",
  Agent = "Agent",
  AgentAndBorrower = "AgentAndBorrower"
}

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent {

  @ViewChild('slider') slider: NgbCarousel | undefined;
  @ViewChild('loginForm') loginForm: NgForm | undefined;

  @ViewChild("twoFactorCodeInput") 
  private _twoFactorCodeInput: ElementRef;

  loginRequest: AuthenticationRequest;

  logoUrl: string = "";
  spinnerVisible: boolean = false;
  loginButtonText: string = "Continue";

  errorMessage: string = "";

  possibleLogoUrls: string[] = [];

  availableCompanies: AuthenticationUserCompany[] = [];
  availableRoles: string[] = [];

  protected userScope: PortalScope = null;

  protected canCreateAccount: boolean = true;

  protected needToConfirmPhone: boolean = false;

  protected mfaSetupRequired: boolean = false;

  protected mfaCodeEntryRequired: boolean = false;

  protected passwordRequired: boolean = false;

  protected verificationCode: string;

  protected phone: string;

  protected areaCode: string;

  protected countries: { name: string, value: string, areaCode: string }[] = [];

  private _returnUrl: string = null;

  private _queryParams: string = "";

  private readonly _activatedRouteSubscription: Subscription;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _authService: AuthService,
    private readonly _navigationService: NavigationService,
    private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _timerService: TimerService,
    private readonly _environment: EnvironmentService,
    private readonly _enumsService: EnumsService,
    private readonly _router: Router
  ) {
    this.loginRequest = new AuthenticationRequest();
    this.loginRequest.usernameValidationOnly = true;
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;
    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      this._returnUrl = params['returnUrl'];
      const userName = params['username'];
      if (userName) {
        this.loginRequest.username = userName;
      }
      Object.keys(params).forEach(key => {
        if (key !== 'returnUrl') {
          this._queryParams += `${key}=${params[key]}&`;
        } else {
          this._queryParams += `r_url=${params[key]}&`;
        }
      });
    });
    let navigation = this._router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.loginRequest.username = navigation.extras.state.userName;
    }
  }

  ngOnInit() {
    let userScopeFromPath = null;
    if (this._activatedRoute.routeConfig && this._activatedRoute.routeConfig.data && this._activatedRoute.routeConfig.data.scope) {
      userScopeFromPath = this._activatedRoute.routeConfig.data.scope;
    }
    this.userScope = this._environment.scope || userScopeFromPath;
    this.canCreateAccount = (this.userScope == PortalScope.Borrower);

    this.countries = this._enumsService.countries;
  }

  ngOnDestroy() {
    this._activatedRouteSubscription?.unsubscribe();
  }

  onAvailableCompanyPickedForLogin = () => {
    if (!this.loginRequest.userCompanyGuid) {
      return;
    }
    const companyThatIsPicked = this.availableCompanies.find(company => company.userCompanyGuid == this.loginRequest.userCompanyGuid);
    if (companyThatIsPicked) {
      this.userScope = companyThatIsPicked.userType as PortalScope;
      this.loginRequest.companyGuid = companyThatIsPicked.companyGuid;
      this.loginRequest.userCompanyGuid = companyThatIsPicked.userCompanyGuid;

      if (companyThatIsPicked.isExternalAuth) {
        this.errorMessage = null;
        this.redirectToExtternalAuthProvider();
        return;
      }
     
      this.passwordRequired = true;
      this.slider.select('askForPassword');
    }
  }

  onCarouselTransitionComplete = (e: NgbSlideEvent) => {
    if (e.current === 'twoFactorCodeEntry') {
      this._twoFactorCodeInput.nativeElement.focus();
    }
  }

  onCreateAccountClicked = async () => {
    const observer: Observer<string> = {
      next: (value: string) => {
        window.location.href = value;
      },
      error: (error: any) => {
        const errorMessage = error?.error || "An error occurred while resetting your password.";
        this.errorMessage = errorMessage;
      },
      complete: () => {
      }
    };
    this._authService.getDefaultOnlineAppUrl(this.loginRequest.companyGuid).subscribe(observer);
  }

  onForgotPasswordClicked = () => {
    this._navigationService.navigateToPath("forgot-password", true);
  }

  onLoginClicked = () => {
    this.loginForm?.form.markAllAsTouched();
    if (this.loginForm?.form.valid) {

      this.loginRequest.scope = this.userScope || PortalScope.AgentAndBorrower;
      this.errorMessage = null;

      if (this.loginRequest.usernameValidationOnly) {
        this.loginRequest.password = null;
      }

      if (this.passwordRequired && this.loginRequest.password) {
        this.passwordRequired = false;
      }
      
      this.toggleProgress(true);

      this._authService.signIn(this.loginRequest).subscribe((response: AuthenticationResponse) => {

        if (response.userCompanyGuid) {
          this.loginRequest.userCompanyGuid = response.userCompanyGuid;
        }

        this.toggleProgress(false);

        if (response.loginStatus === LoginStatus.Error) {
          this.onLoginErrored(response);
          return;
        } else if (response.loginStatus === LoginStatus.CompanySelectionRequired) {
          this.onCompanySelectionRequired(response.availableCompanies);
          return;
        } else if (response.loginStatus === LoginStatus.UsernameValidationComplete) {
          this.onUserNameValidationComplete(response.availableCompanies);
          return;
        } else if (response.loginStatus === LoginStatus.ProperScopeSelectionRequired) {
          this.onRoleSelectionRequired(response.availableCompanies);
          return;
        } else if (response.loginStatus === LoginStatus.TwoFactorAuthenticationSetupRequired) {
          if (!this.loginRequest.userCompanyGuid && response.availableCompanies.length > 0) {
            this.loginRequest.userCompanyGuid = response.availableCompanies[0].userCompanyGuid;
          }
          this.onMfaSetupRequired();
          return;
        } else if (response.loginStatus === LoginStatus.TwoFactorAuthenticationRequired) {
          if (!this.loginRequest.userCompanyGuid && response.availableCompanies.length > 0) {
            this.loginRequest.userCompanyGuid = response.availableCompanies[0].userCompanyGuid;
          }
          this.onTwoFactorCodeRequired();
          return;
        }

        // start auto logout timer
        const authData = JSON.parse(localStorage.getItem(Constants.authorization.authorizationDataKey))

        if (authData) {
          let expireDate = authData.expiresAt;

          this.userScope = authData.scope;

          let endDate = DateTime.fromISO(expireDate);
          let now = DateTime.fromISO(new Date().toISOString());

          const diff = Interval.fromDateTimes(now, endDate);
          const diffMinutes = diff.length('minutes');

          this._timerService.start("auto-logout-timer", Math.ceil(diffMinutes), false, true);

          if (authData.companyGuid) {
            this._navigationService.companyGuid = authData.companyGuid;
          }
        }

        if (this._returnUrl) {
          let returnUrl = this._returnUrl;
          this._navigationService.navigateToPath(returnUrl, true);
          return;
        }

        if (this.userScope) {
          PageUtils.fixLayoutMarkerBasedOnScope(this.userScope);
          this.setPageTitle(this.loginRequest.companyGuid, this.userScope);

          const path = this.userScope == PortalScope.Borrower ? "borrower-portal" : "agent-portal";
          this._navigationService.navigateToPath(path, true);
        } else {
          // Here we can navigate to the wizard flow again
          this._wizardFlowService.navigateToApplicationFlow();
        }
      },
        err => {
          this.loginRequest.companyGuid = null;
          this.loginRequest.userCompanyGuid = null;
          this.loginRequest.usernameValidationOnly = true;
          this.toggleProgress(false);
          this.errorMessage = "User name or password is incorrect.";
        });
    }
  }

  onCancelLoginClicked = () => {
    this.errorMessage = undefined;
    this.slider.select('askForEmail');
    this.loginRequest.companyGuid = null;
    this.loginRequest.userCompanyGuid = null;
    this.loginRequest.twoFactorCode = null;
    this.loginRequest.scope = null;
    this.loginRequest.username = null;
    this.loginRequest.password = null;

    this.mfaSetupRequired = false;
    this.needToConfirmPhone = false;
    this.mfaCodeEntryRequired = false;

    this.phone = null;
    this.areaCode = null;
    this.verificationCode = null;

    Object.keys(this.loginForm?.form.controls).forEach(key => {
      this.loginForm?.form.controls[key].markAsUntouched();
    });
  }

  onChangeNumberClicked = () => {
    this.needToConfirmPhone = false;
  }

  protected onSendCodeClicked = () => {
    this.loginRequest.twoFactorCode = null;
    this._spinner.show();
    this._authService.sendTwoFactorPhoneCode(this.loginRequest.userCompanyGuid).subscribe(response => {
      Swal.fire(
        'Sent Code',
        'We sent another code to your phone. Please check your phone and use that code to login.',
        'success'
      )
    }, err => {
    }).add(() => {
      this._spinner.hide();
    });
  }

  protected onConfirmPhoneClicked = () => {
    this.toggleProgress(true);
    this._authService.confirmUpdatePhoneAnonymous(this.phone, this.areaCode, this.verificationCode, this.loginRequest)
      .pipe(finalize(() => {
        this.toggleProgress(false);
      }))
      .subscribe({
        next: () => {
          this.onLoginClicked();
        },
        error: (error) => {
          this.toggleProgress(false);
          if (error?.code === "InvalidToken") {
            this.errorMessage = error.description;
          } else {
            this.errorMessage = "Couldn't process the confirmation code.";
          }
        }
      })
  }

  onSaveNumberClicked() {
    this.toggleProgress(true);
    this._authService.updatePhoneAnonymous(this.phone, this.areaCode, this.loginRequest)
      .pipe(finalize(() => this.toggleProgress(false)))
      .subscribe({
        next: (res) => {
          this.needToConfirmPhone = true;
        },
        error: (error) => {
          this.errorMessage = getErrorMessageOrDefault(error, {
            defaultMessage: 'Unable to update phone number.',
          })
        }
      });
  }

  private redirectToExtternalAuthProvider = () => {
    const returnUrl = `${window.location.protocol}//${window.location.host}/ext-auth-oap-redirect?${this._queryParams}`;
    const challengeUrl = this._environment.apiInfo.apiBaseUrl + `/api/auth/connect/challenge/${this.loginRequest.companyGuid}/Borrower?returnUrl=${encodeURIComponent(returnUrl)}&userName=${this.loginRequest.username}`;
    window.location.href = challengeUrl;
  }

  private setPageTitle = (companyGuid: string, userScope: PortalScope) => {
    if (userScope) {
      document.title = userScope == 'Borrower' ? "Borrower Portal" : "Agent Portal";
    }
  }

  private onLoginErrored = (response: AuthenticationResponse) => {
    this.toggleProgress(false);
    this.loginRequest.usernameValidationOnly = true;
    this.errorMessage = response.errorMessage;
  }

  private onMfaSetupRequired = () => {
    this.loginRequest.usernameValidationOnly = false;
    this.toggleProgress(false);
    this.mfaSetupRequired = true;
    this.slider.select('mfaSetup');
  }

  private onTwoFactorCodeRequired = () => {
    this.loginRequest.usernameValidationOnly = false;
    this.toggleProgress(false);
    this.mfaCodeEntryRequired = true;
    this.slider.select('twoFactorCodeEntry');
  }

  private onUserNameValidationComplete = (availableCompanies: AuthenticationUserCompany[]) => {
    this.loginRequest.usernameValidationOnly = false;
    this.availableCompanies = availableCompanies;
    this.loginRequest.userCompanyGuid = availableCompanies[0].userCompanyGuid;
    this.onAvailableCompanyPickedForLogin();
  }

  private onCompanySelectionRequired = (availableCompanies: AuthenticationUserCompany[]) => {
    this.loginRequest.usernameValidationOnly = false;
    this.availableCompanies = availableCompanies;
    if (availableCompanies.length == 1) {
      this.loginRequest.userCompanyGuid = availableCompanies[0].userCompanyGuid;
      this.onAvailableCompanyPickedForLogin();
      return;
    }
    if (this.slider) {
      this.slider.select('companySelection');
    }
  }

  private onRoleSelectionRequired = (availableCompanies: AuthenticationUserCompany[]) => {
    this.loginRequest.usernameValidationOnly = false;
    if (this.slider) {
      this.slider.select('roleSelection');
      this.availableRoles = availableCompanies.map(c => c.userType);
    }
  }

  private toggleProgress = (value: boolean) => {
    if (value) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
    this.spinnerVisible = value;
    if (value) {
      this.loginButtonText = "Please wait...";
    } else {
      this.loginButtonText = "Continue";
    }
  }
}
