
<ng-template #digitalAsset>
  <div *ngIf="voaEnabled && !isEditEnabled" id="digital-assets-order-container"></div>
  <div class="card-px text-center py-20 my-10" *ngIf="!voaEnabled && !isEditEnabled">
    <h2 class="fs fw-bold mb-8">Digital Assets Not Available</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">We are not able to pull your assets digitally at the moment.
    <br>You will have to tell us about your assets manually.</p>
    <a (click)="onSpecifyAssetsClicked()" class="btn btn-primary">Tell Us About Your Assets</a>
  </div>
  <div class="card-px text-center py-20 my-10" *ngIf="isEditEnabled">
    <h2 class="fs fw-bold mb-8">Here will go the Digital Assets</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">In normal flow (outside of edit mode), digital assets will be displayed here.</p>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="digitalAsset" [stepComponent]="this" [step]="step"></wizard-step-template>

