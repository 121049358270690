import { Injectable } from "@angular/core";
import { DataService } from "./data.service";

@Injectable()
export class IncomeService {

    constructor(private readonly _dataService: DataService)
    {
    }

    public verifyIncomeAndEmploymentData(applicationId: number, borrowerContactId: number) {
      const url = `api/online-app/twn/verification`;
      const payLoad = {
        applicationId: applicationId,
        borrowerContactId: borrowerContactId,
        requestType: 'IncomeAndEmployment',
        employeeStatusFilter: 'Active'
        };
      return this._dataService.post(url, payLoad);
    }
  }
