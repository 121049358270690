
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'auth-req': 'true', 'X-Requested-With': 'XMLHttpRequest' });

@Injectable()
export class AssetService {

    constructor(private readonly _dataService: DataService)
    {
    }

    public markFormFreeForBorrowerComplete = (borrowerId: number, applicationId: number): Observable<any> => {
        const url = `api/online-app/MarkFormFreeForBorrowerComplete/${applicationId}/${borrowerId}`;
        return this._dataService.post(url, {});
    }

    public initializeFormFree = (borrowerId: number, applicationId: number, maxHeight: number = 600, maxWidth: number = 800): Observable<any> => {
      const url = `api/online-app/InitializeFormFreeForBorrower/${applicationId}/${borrowerId}?maxHeight=${maxHeight}&maxWidth=${maxWidth}`;
      return this._dataService.post(url, {});
    }
}
