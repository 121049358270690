import { BorrowerAppStatus } from "./borrower-app-status.model";
import { Borrower } from "./borrower-model";
import { FeatureFlags } from "./feature-flags.model";
import { Lead } from "./lead.model";
import { MortgageApplication } from "./mortgage-app.model";
import { Originator } from "./originator.model";
import { WizardFlowCustomerPreferences } from "./wizard-flow-customer-preferences.model";

export class WizardFlowContext {
  mortgage!: MortgageApplication;
  customerPreferences!: WizardFlowCustomerPreferences;
  currentBorrower!: Borrower;
  originator: Originator | undefined;

  creditRunFailed: boolean = false;
  skipCreditCheck: boolean = false;
  skipAutomatedCreditCheck: boolean = false;

  refiAddressSameAsCurrentAddress: boolean | undefined = undefined;

  //willApplyForBorrower TODO: rename this, the naming of this sucks!
  borrowerSettings: Map<number, boolean> = new Map();
  borrowerAppStatus: BorrowerAppStatus;

  thereAreLoanCharacteristics: boolean = false;
  thereAreBorrowerCharacteristics: boolean = false;

  config: FeatureFlags | undefined;

  applicationInfo: any | undefined;

  companyGuid: string | undefined | null;
  companyId: number | undefined | null;
  applicationId: number | undefined | null;

  leadSource: string = "";
  leadSourceType: string = "";

  hasMultipleBorrowers: boolean | null = null;

  hasSuccessfullyPulledExistingApplication: boolean = false;

  lead: Lead | undefined;

  constructor(mortgage: MortgageApplication, customerPreferences: WizardFlowCustomerPreferences) {
    this.mortgage = mortgage;
    this.customerPreferences = customerPreferences;
    this.currentBorrower = this.mortgage.borrowers[0];
    this.borrowerAppStatus = new BorrowerAppStatus();
  }

  getBorrowersThatAreNotPrimary = () => {
    let borrowers: Borrower[] = [];
    this.mortgage.borrowers.forEach(b => {
      const selected = this.borrowerSettings.get(b.borrowerId);
      if (selected && selected === true) {
        borrowers.push(b);
      }
    });
  }
}
