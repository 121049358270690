<ng-template #verifyIncome>

  <div class="card-px text-center py-20 my-10" *ngIf="!voeEnabled">
    <h2 class="fs fw-bold mb-8">Digital Income Verification Not Available</h2>
    <p class="text-gray-400 fs-4 fw-semibold mb-10">We are not able to verify your income digitally at the moment.
    <br>You will have to tell us about your income manually.</p>
    <a (click)="onSpecifyIncomeAndEmploymentClicked()" class="btn btn-primary">Tell Us About Your Employment/Income</a>
  </div>

  <ng-container *ngIf="voeEnabled">
    <div class="card shadow-sm mb-5">
      <div class="card-header">
          <h3 class="card-title">{{mainBorrower.firstName + " " + mainBorrower.lastName}}</h3>
      </div>
      <div class="card-body">
        <borrower-digital-income #borrowerDigitalIncome [borrowerIndex]="0" [borrower]="mainBorrower"></borrower-digital-income>
      </div>
    </div>

    <ng-container *ngIf="!inCoApplyFlow">
      <ng-container *ngFor="let borrower of coBorrowers; let i = index">
        <div *ngIf="borrowerSetting(borrower)" class="card shadow-sm mb-5">
            <div class="card-header">
              <h3 class="card-title">{{borrower.firstName + " " + borrower.lastName}}</h3>
            </div>
            <div class="card-body">
              <borrower-digital-income #borrowerDigitalIncome [borrowerIndex]="i+1" [borrower]="borrower"></borrower-digital-income>
            </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

</ng-template>

<wizard-step-template [stepMainTemplate]="verifyIncome" [step]="step" [stepComponent]="this"></wizard-step-template>
