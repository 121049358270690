import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Borrower } from '../../../models/borrower-model';
import { AssetService } from '../../../services/asset-service';
import { WizardStepComponentBase } from '../wizard-step-base.component';

declare const $: any;
@Component({
  selector: 'digital-assets-step',
  templateUrl: 'digital-assets-step.component.html',
  styleUrls: ['digital-assets-step.component.scss'],
})
export class DigitalAssetsStepComponent
  extends WizardStepComponentBase<any>
  implements OnInit {

  voaEnabled: boolean = true;

  private _borrower!: Borrower;

  constructor(
    private readonly _injector: Injector,
    private readonly _assetService: AssetService,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(_injector);
    this._borrower = this.mortgageApplication.borrowers[0];
    if (!this.isEditEnabled) {
      this.voaEnabled = this.wizardFlowService.context.config.voaEnabled;
    }
  }

  ngOnInit() {
    this.spinner.show();
    if (!this.voaEnabled) {
      return;
    }
    this._assetService
      .initializeFormFree(
        this._borrower.contactId,
        this.mortgageApplication.applicationId,
        800,
        600
      )
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          if (result.success) {
            result.htmlWidgetSrc = result.htmlWidgetSrc.replace(
              'element.parentNode.removeChild(element);',
              ''
            );
            $('#digital-assets-order-container').html(result.htmlWidgetSrc);
            window.addEventListener('message', this.messageListener);
          } else {
            this.showError({
              title: 'Error occurred initializing FormFree',
              message: result.errorMessage,
            });
          }
        },
        (error) => { }
      );
  }

  onSpecifyAssetsClicked = () => {
    this.wizardFlowService.navigateToManualAssetsStep();
  }

  onNextClicked(): void {
    if (!this.voaEnabled) {
      this.wizardFlowService.navigateToManualAssetsStep();
      return;
    }
    super.onNextClicked();
  }

  private messageListener = (msg: any) => {
    if (msg.data === 'closeFrame') {
      this._spinner.show();
      this._assetService
        .markFormFreeForBorrowerComplete(
          this._borrower.contactId!,
          this.mortgageApplication.applicationId
        )
        .subscribe(() => {
          this._spinner.hide();
          this.onNextClicked();
        }, error => {
          this._spinner.hide();
          //const message = error.error && error.error.message ? error.error.message : "An error occurred while sharing assets digitally."
          //const errorMessage = new ErrorMessage("Error!", message);
          //this.showError(errorMessage)
          this.onNextClicked();
        });
      window.removeEventListener('message', this.messageListener);
    }
  };
}
