import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AgentFull } from "../models/agent.model";
import { ReferralSource } from "../models/referral-source.model";
import { DataService } from "./data.service";

@Injectable()
export class AgentService {

  constructor(private readonly _dataService: DataService) {
  }

  getAllReferralSources = (): Observable<ReferralSource[]> => {
    return this._dataService.get("api/Agent/AllReferralSources");
  }

  getTitleReferralSources = (): Observable<ReferralSource[]> => {
    const url = 'api/Agent/AllReferralSources?agentTypeFilter=Title';
    return this._dataService.get(url);
  }

  getHoiReferralSources = (): Observable<ReferralSource[]> => {
    const url = 'api/Agent/AllReferralSources?agentTypeFilter=HOI';
    return this._dataService.get(url);
  }

  getEscrowReferralSources = (): Observable<ReferralSource[]> => {
    const url = 'api/Agent/AllReferralSources?agentTypeFilter=Escrow';
    return this._dataService.get(url);
  }

  getAgent = (agentId: number): Observable<AgentFull> => {
    const url = 'api/Agent/GetAgent/' + agentId;
    return this._dataService.get(url);
  }

}
