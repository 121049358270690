import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import { Constants } from './constants';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { FlowType } from '../models/wizard/config/flow-type.enum';

@Injectable()
export class NavigationService {

  companyGuid: string | undefined = undefined;
  branchGuid: string | undefined = undefined;
  userGuid: string | undefined = undefined;
  flowGuid: string | undefined = undefined;
  editToken: string | undefined = undefined;

  constructor(
    private readonly _router: Router,
    private readonly _utilsService: UtilsService,
    private readonly _location: Location,
    private readonly _environment: EnvironmentService) {
    this.companyGuid = this._utilsService.getUrlParameter(Constants.companyGuid);
    this.branchGuid = this._utilsService.getUrlParameter(Constants.branchGuid);
    this.userGuid = this._utilsService.getUrlParameter(Constants.userGuid);
    this.flowGuid = this._utilsService.getUrlParameter(Constants.flowGuid);
    this.editToken = this._utilsService.getUrlParameter(Constants.editToken);
  }

  get possibleLogoUrls(): string[] {
    let possibleLogoUrls: string[] = [];
    if (this.branchGuid) {
      possibleLogoUrls.push(this._environment.branchLogoUrl + this.branchGuid);
    } else {
      possibleLogoUrls.push(this._environment.branchLogoUrl);
    }
    if (this.companyGuid) {
      possibleLogoUrls.push(this._environment.companyLogoUrl + this.companyGuid);
    } else {
      possibleLogoUrls.push(this._environment.companyLogoUrl);
    }
    return possibleLogoUrls;
  }

  navigateToLogin = (companyGuid?: string, setReturnUrl: boolean = true) => {
    if (location.pathname && location.pathname.toLowerCase() === '/login') {
      return;
    }
    let queryParams: any = {};
    if (companyGuid) {
      queryParams.companyGuid = companyGuid;
    }
    if (location.pathname && setReturnUrl) {
      queryParams.returnUrl = location.pathname;
    }
    this.navigateToPath('login', true, null, queryParams);
  }

  navigateToCheckEmailNotice = (email: string) => {
    let queryParams = this.generateQueryParameters();
    queryParams.email = email;
    const url = this._router.createUrlTree(['check-email-notice'], { queryParams: queryParams }).toString()
    this._router.navigateByUrl(url);
    history.pushState(null, '', location.href);
  }

  navigateToCheckEmailForPasswordResetNotice = (email: string) => {
    let queryParams = this.generateQueryParameters();
    queryParams.email = email;
    const url = this._router.createUrlTree(['check-email-password-reset-notice'], { queryParams: queryParams }).toString()
    this._router.navigateByUrl(url);
    history.pushState(null, '', location.href);
  }

  navigateToResetPassword = (email: string) => {
    let queryParams = this.generateQueryParameters();
    queryParams.email = email;
    const url = this._router.createUrlTree(['reset-password'], { queryParams: queryParams }).toString()
    this._router.navigateByUrl(url);
    history.pushState(null, '', location.href);
  }

  navigateToFlow = (flowGuid: string | undefined, initialPath: string) => {
    let queryParams = this.generateQueryParameters();
    if (flowGuid) {
      queryParams.flowGuid = flowGuid;
    }
    const lastUrlSegment = this._utilsService.getLastUrlSegment();
    const url = this._router.createUrlTree([lastUrlSegment], { queryParams: queryParams }).toString()

    this._location.go(url);
  }

  navigateToFlowEditor = (flowGuid: string, activeFlowType?: FlowType): void => {
    let queryParams = this.generateQueryParameters();
    queryParams.flowGuid = flowGuid;
    var queryString = this.generateQueryString(queryParams);
    if (activeFlowType) {
      queryString = queryString + '&flowType=' + activeFlowType;
    }
    const pathToGo = 'flow-editor-v2' + queryString;
    this._router.navigateByUrl(pathToGo)
      .then(() => {
        window.location.reload();
      });
  }

  navigateToPath = (path: string, overrideUrl?: boolean, state: any = null, queryParams: any = null, keepExistingQueryParams: boolean = true): void => {
    let existingQueryParams = this.generateQueryParameters();
    let queryParamsToUse = queryParams;
    if (keepExistingQueryParams) {
      queryParamsToUse = { ...existingQueryParams, ...queryParams };
    }

    const url = this._router.createUrlTree([path], { queryParams: queryParamsToUse }).toString()
    if (overrideUrl) {
      this._router.navigateByUrl(url, { state: state });
      history.pushState(null, '', location.href);
      return;
    }
    setTimeout(() => {
      this._router.navigateByUrl(url, { replaceUrl: true, skipLocationChange: true, state: state });
      history.pushState(null, '', location.href);
    });
  }

  redirectToBorrowerPortalLoansWithAppId = (borrowerPortalUrl: string, appId: number) => {
    const authorizationDataKey: string = "authorizationData";
    const authDataObj = JSON.parse(localStorage.getItem(authorizationDataKey)!);
    const token = authDataObj.token;
    let redirectUrl = "https://" + borrowerPortalUrl + "/loans-redirect/" + appId + "?authToken=" + token;
    window.open(redirectUrl, "_blank");
  }

  redirectToBorrowerPortalLoans = (borrowerPortalUrl: string) => {
    const authorizationDataKey: string = "authorizationData";
    const authDataObj = JSON.parse(localStorage.getItem(authorizationDataKey)!);
    const token = authDataObj.token;
    let redirectUrl = "https://" + borrowerPortalUrl + "/loans-redirect?authToken=" + token;
    window.location.href = redirectUrl;
  }

  private generateQueryString = (queryParams: any): string => {
    let queryString = queryParams.companyGuid ? "?" + Constants.companyGuid + "=" + queryParams.companyGuid : "";
    queryString = this.appendParameter(queryString, Constants.userGuid, queryParams.userGuid);
    queryString = this.appendParameter(queryString, Constants.flowGuid, queryParams.flowGuid);
    queryString = this.appendParameter(queryString, Constants.editToken, queryParams.editToken);
    queryString = this.appendParameter(queryString, Constants.userCompanyGuid, queryParams.userCompanyGuid);
    return queryString;
  }

  private generateQueryParameters = (): any => {
    let queryParams: any = {};
    const companyGuid = this._utilsService.getUrlParameter(Constants.companyGuid);
    if (companyGuid) {
      queryParams.companyGuid = companyGuid;
    }
    const userGuid = this._utilsService.getUrlParameter(Constants.userGuid);
    if (userGuid) {
      queryParams.userGuid = userGuid;
    }
    const userCompanyGuid = this._utilsService.getUrlParameter(Constants.userCompanyGuid);
    if (userCompanyGuid) {
      queryParams.userCompanyGuid = userCompanyGuid;
    }
    const flowGuid = this._utilsService.getUrlParameter(Constants.flowGuid);
    if (flowGuid) {
      queryParams.flowGuid = flowGuid;
    }
    const editToken = this._utilsService.getUrlParameter(Constants.editToken);
    if (editToken) {
      queryParams.editToken = editToken;
    }
    const userName = this._utilsService.getUrlParameter(Constants.userName);
    if (userName) {
      queryParams.username = userName;
    }
    const token = this._utilsService.getUrlParameter(Constants.token);
    if (token) {
      queryParams.token = token;
    }
    const appId = this._utilsService.getUrlParameter(Constants.applicationId);
    if (appId) {
      queryParams.appId = appId;
    }
    // if (location.pathname.toLocaleLowerCase() === '/login') {
    //   const returnUrl = this._utilsService.getUrlParameter(Constants.returnUrl);
    //   if (returnUrl) {
    //     queryParams.returnUrl = returnUrl;
    //   }
    // }
    return queryParams;
  }

  private appendParameter = (queryString: string, name: string, value: string | undefined): string => {
    if (value) {
      let separator: string = queryString.length > 0 ? "&" : "?";
      queryString += separator + name + "=" + value;
    }
    return queryString;
  }
}
