import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export class AssetVerificationOptions {
  digital: string = "1";
  manual: string = "2";
}

export class IncomeVerificationOptions {
  digital: string = "1";
  manual: string = "2";
}

export class FlowEndpoints {
  register: string = "register";
  apply: string = "apply";
  coApply: string = "co-apply";
  borrowerPortalEntry: string = "b-portal-redirect";
  error: string = "error";
}

export class Authorization {
  authorizationDataKey: string = "authorizationData";
  adminAuthorizationDataKey: string = "adminAuthorizationData";
}

export class StepPaths {
  myInfo: string = "my-info";
  numberOfBorrowers: string = "number-of-borrowers";
  address: string = "address";
  createAccount: string = "create-account";
  createCoBorrowers: string = "create-coborrowers";
  currentAddress: string = "address";
  ownershipStatus: string = "own-rent";
  addressHistory: string = "address-history";
  manualOrDigitalEmploymentAndIncomeChoice: string = "employment-income";
  manualOrDigitalAssetChoice: string = "asset-manual-digital"
  digitalIncomeAndEmployment: string = "digital-income";
  manualIncomeAndEmployment: string = "income";
  digitalAssets: string = "digital-assets";
  manualAssets: string = "assets";
  reo: string = "reo";
  verifyCredit = "verify-credit";
  hoi = "hoi";
  purchaseCredits = 'purchase-credits';
  creditScores = "credit-scores"
  reviewCredit = "review-credit";
  creditInquiry = "credit-inquiry";
  loanInfo = "loan-type";
  pricing = "pricing";
  pullExistingApplication = "pull-existing-application";
  declarations = "declarations";
  demographics = "demographics";
  militaryService = "military-service";
  loanCharacteristics = "loan-characteristics";
  borrowerCharacteristics = "borrower-characteristics";
  econsent = "eConsentAuthorization";
  reviewApplication = "review-application";
  submitApplication = "submit-application";
  originator = "originator";
  multiBorrowerAddressHistory = "multi-borrower-address-history";
  loanPurpose = "loan-purpose";
  currentAppState = "current-app-state";
  propertyAddressRefi = "property-address-refi";
  propertyAddress = "property-address";
  occupancy = "occupancy";
  propertyType = "property-type";
  checklists = "checklists";
  gatherLeadSource = "gather-lead-source";
  linkLiabilitiesToReo = "link-liabilities-to-reo";
  homeownershipEducation = "homeownership-education";
}

export class ModalOptions {
  large: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
    scrollable: true,
  };
  xlarge: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
    centered: true,
    //windowClass: 'modal-window-class'
    scrollable: true,
  };
  medium: NgbModalOptions = {
    size: 'md',
    backdrop: 'static',
    centered: true,
    scrollable: true,
  };
  sixtyPercentOfScreenWidth: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
    centered: true,
    windowClass: 'modal-dialog-60pct-of-screen-width'
  }
  seventyFivePercentOfScreenWidth: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
    centered: true,
    windowClass: 'modal-dialog-75pct-of-screen-width'
  }
}

export class ThirdPartyTrackingScriptConfig {
  codeScripts?: string[] = [];
  noscriptPixelTrackers?: string[] = [];
  scriptLinks?: scriptLink[] = [];
  key: string = "";
}

export class scriptLink {
  source: string = "";
  async: boolean = true;
  defer: boolean = false;
  id: string = "";
}

export class scriptAsCode {
  code: string = "";
}

export class noScript {
  innerHtml: string = ""
}

export class ThirdPartyTrackingScripts {

  facebook: ThirdPartyTrackingScriptConfig = {
    codeScripts: [
      `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '|facebookTrackingCode|');
      fbq('track', 'PageView');`
    ],
    noscriptPixelTrackers: [`https://www.facebook.com/tr?id=|facebookTrackingCode|&ev=PageView&noscript=1"`],
    scriptLinks: [],
    key: '|facebookTrackingCode|'
  }

  google: ThirdPartyTrackingScriptConfig = {
    codeScripts: [
      `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '|googleTrackingCode|');`
    ],
    scriptLinks: [
      {
        source: `https://www.googletagmanager.com/gtag/js?id=|googleTrackingCode|`,
        async: true,
        defer: false,
        id: ''
      }
    ],
    key: '|googleTrackingCode|'
  }

  hubSpot: ThirdPartyTrackingScriptConfig = {
    scriptLinks: [
      {
        source: `//js.hs-scripts.com/|hubspotTrackingCode|.js`,
        async: true,
        defer: true,
        id: `hs-script-loader`
      }
    ],
    key: '|hubspotTrackingCode|'
  }
}

export class MortgageEnumerations {
  loanPurpose: string = 'LoanPurposeType';
  refinancePurpose: string = 'RefinancePurposeType';
  amortizationType: string = 'AmortizationType';
  propertyType: string = 'PropertyType';
  propertyTitleType: string = 'PropertyTitleType';
  mortgageAppliedForType: string = 'MortgageAppliedForType';
  subjectProperty: string = 'SubjectProperty';
  appraisalFormType: string = "AppraisalFormType";
  //propertyInspectionType: string = "PropertyInspectionType";
  appraisalMethodType: string = "AppraisalMethodType";
  estateType: string = 'EstateType';
  recordingJurisdictionType: string = 'RecordingJurisdictionType';
  titleType: string = 'PropertyTitleType';
  propertyTrustClassification: string = 'PropertyTrustClassification';
  nativeAmericanLandsType: string = 'NativeAmericanLandsType';
  productType: string = 'ProductType';
  lendingProductType: string = 'CommunityLendingProductType';
  secondsRepaymentType: string = 'CommunitySecondsRepaymentType';
  negativeAmortizationType: string = 'NegativeAmortizationType';
  armIndexType: string = 'ArmIndexSourceType';
  governmentRefinanceType: string = 'GovernmentRefinanceType';
  constructionToPermanentClosingType: string = 'ConstructionToPermanentClosingType';
  projectType: string = 'ProjectType';
  propertyAttachmentType: string = 'PropertyAttachmentType';
  creditorServicingOfLoanStatementType: string = 'CreditorServicingOfLoanStatementType';
  paymentBillingStatementFrequencyType: string = 'PaymentBillingStatementFrequencyType';
  loanUnderwritingSubmitterType: string = 'LoanUnderwritingSubmitterType';
  lateChargeType: string = 'LateChargeType';
  partialPaymentApplicationMethodType: string = 'PartialPaymentApplicationMethodType';
  mortgagePaymentFrequency: string = 'MortgagePaymentFrequency';
  projectDesignType: string = 'ProjectDesignType';
  improvementStatusType: string = 'ImprovementStatusType';
  propertyWillBeType: string = "PropertyWillBeType";
  landValueType: string = 'LandValueType';
  mortgageDocumentSet: string = 'MortgageDocumentSet';
  constructionMethodType: string = 'PropertyConstructionMethod';
  attachmentType: string = 'PropertyAttachmentType';
  manufacturedHomeWidthType: string = 'ManufacturedHomeWidthType';
  premiumRefundableType: string = 'MIPremiumRefundableType';
  renewalType: string = 'MIRenewalType';
  renewalCalculationType: string = 'MIRenewalCalculationType';
  premiumRatePlanType: string = 'MIPremiumRatePlanType';
  paymentFrequencyType: string = 'MIPaymentFrequencyType';
  durationType: string = 'MIDurationType';
  premiumSourceType: string = 'MIPremiumSource';
  lienPositionType: string = 'LienPositionType';
  governmentLoanSectionOfActType: string = 'GovernmentLoanSectionOfActType';
  fhaInsuranceProgramType: string = 'FhaInsuranceProgramType';
  vaFundingFeeExemptionType: string = 'VAFundingFeeExemptionType';
  language: string = 'Language';
  selfEmploymentForm: string = "SelfEmploymentForm"
  ausSystemType: string = "AusSystemType"
  freddieMacRefinanceProgram: string = "FreddieMacRefinanceProgram"
  freddieMacLoanProgram: string = "FreddieMacLoanProgram"
  giftGrantSource: string = "GiftGrantSource";
  hazardInsuranceCoverageType: string = "HazardInsuranceCoverageType";
  manufacturedHomeConditionType: string = "ManufacturedHomeConditionType";
  certificateOfTitleType: string = "CertificateOfTitleType";
  lotOwnershipType: string = "LotOwnershipType";
  governmentLoanApplicationType: string = "GovernmentLoanApplicationType";
  fHAAlimonyLiabilityTreatmentType: string = "FHAAlimonyLiabilityTreatmentType";
  vaAppraisalType: string = "VAAppraisalType";
  vaLoanProcedureType: string = "VALoanProcedureType";
  vaTitleVestingType: string = "VATitleVestingType";
  propertyOccupancyType: string = "PropertyOccupancyType";
  unitOwnedByType: string = "UnitOwnedByType";
  currentPropertyWillBeType: string = "CurrentPropertyWillBeType";
  condominiumProjectStatusType: string = "CondominiumProjectStatusType";
  nFIPCommunityParticipationStatus: string = "NFIPCommunityParticipationStatus";
  hMDAPreapprovalType: string = "HMDAPreapprovalType";
  borrowerAliasType: string = "BorrowerAliasType";
  borrowerRelationshipTitleType: string = "BorrowerRelationshipTitleType";
  signingRole: string = "SigningRole";
  informationalFormSeriesTranscriptRequestedPositionType: string = "InformationalFormSeriesTranscriptRequestedPositionType";
  prepaymentPenaltyPrincipalBalanceType: string = "PrepaymentPenaltyPrincipalBalanceType";
  prepaymentPenaltyOptionType: string = "PrepaymentPenaltyOptionType";
  qualifyingRateType: string = "QualifyingRateType";
  qualifiedMortgageTemporaryGSEType: string = "QualifiedMortgageTemporaryGSEType";
  abilityToRepayMethodType: string = "AbilityToRepayMethodType";
  qualifiedMortgageType: string = "QualifiedMortgageType";
  presumptionOfComplianceType: string = "PresumptionOfComplianceType";
  abilityToRepayExemptionCreditorOrganizationType: string = "AbilityToRepayExemptionCreditorOrganizationType";
  abilityToRepayExemptionLoanProgramType: string = "AbilityToRepayExemptionLoanProgramType";
  buydownCalculationType: string = "BuydownCalculationType";
  militaryBranch: string = "MilitaryBranch";
  vaBorrowerCertificationOccupancyType: string = 'VABorrowerCertificationOccupancyType';
}

export class MimeTypes {
  pdf: string = "application/pdf";
  html: string = "text/html";
  xml: string = "text/xml";
  octetStream: string = "application/octet-stream";
}

export class EnumerationValueNames {
  MortgageAppliedForType = {
    VA: 'MortgageAppliedForTypeVA',
    FHA: 'MortgageAppliedForTypeFHA',
    Conventional: 'MortgageAppliedForTypeConventional',
    USDA: 'MortgageAppliedForTypeUSDA',
    OtherMortgage: 'MortgageAppliedForTypeOtherMortgage',
  };

  AmortizationType = {
    ARM: 'AmortizationTypeARM',
    OtherAmortization: 'AmortizationTypeOtherAmortization',
  };

  LoanPurposeType = {
    Purchase: 'LoanPurposeTypePurchase',
    Refinance: 'LoanPurposeTypeRefinance',
    ConstructionToPermanent: 'LoanPurposeTypeConstructionToPermanent',
  };

  EstateType = {
    FeeSimple: 'EstateTypeFeeSimple',
    Leasehold: 'EstateTypeLeasehold',
  };

  ResidencyBasis = {
    Rent: 'ResidencyBasisRent',
  };

  RaceType = {
    Asian: 'RaceTypeAsian',
    AmericanIndianOrAlaskaNative: 'RaceTypeAmericanIndianOrAlaskaNative',
    NativeHawaiianOrOtherPacificIslander:
      'RaceTypeNativeHawaiianOrOtherPacificIslander',
    BlackOrAfricanAmerican: 'RaceTypeBlackOrAfricanAmerican',
    White: 'RaceTypeWhite',
    InformationNotProvided: 'RaceTypeInformationNotProvided',
    NotApplicable: 'RaceTypeNotApplicable',
  };

  EthnicityType = {
    HispanicOrLatino: 'EthnicityTypeHispanicOrLatino',
    NotHispanicOrLatino: 'EthnicityTypeNotHispanicOrLatino',
    InformationNotProvided: 'EthnicityTypeInformationNotProvided',
    NotApplicable: 'EthnicityTypeNotApplicable',
  };

  SexType = {
    Female: 'SexTypeFemale',
    Male: 'SexTypeMale',
    InformationNotProvided: 'SexTypeInformationNotProvided',
    NotApplicable: 'SexTypeNotApplicable',
  };

  EthnicityOriginType = {
    Other: 'EthnicityOriginTypeOther',
    Mexican: 'EthnicityOriginTypeMexican',
    PuertoRican: 'EthnicityOriginTypePuertoRican',
    Cuban: 'EthnicityOriginTypeCuban',
  };

  AssetType = {
    CheckingAccount: 'AssetTypeCheckingAccount',
    SavingsAccount: 'AssetTypeSavingsAccount',
    Stock: 'AssetTypeStock',
    StockOptions: 'AssetTypeStockOptions',
    Bond: 'AssetTypeBond',
    LifeInsurance: 'AssetTypeLifeInsurance',
    RetirementFund: 'AssetTypeRetirementFund',
    NetWorthOfBusinessOwned: 'AssetTypeNetWorthOfBusinessOwned',
    Automobile: 'AssetTypeAutomobile',
    OtherLiquidAssets: 'AssetTypeOtherLiquidAssets',
    OtherNonLiquidAssets: 'AssetTypeOtherNonLiquidAssets',
    BridgeLoanNotDeposited: 'AssetTypeBridgeLoanNotDeposited',
    CertificateOfDepositTimeDeposit: 'AssetTypeCertificateOfDepositTimeDeposit',
    IndividualDevelopmentAccount: 'AssetTypeIndividualDevelopmentAccount',
    MoneyMarketFund: 'AssetTypeMoneyMarketFund',
    MutualFund: 'AssetTypeMutualFund',
    TrustAccount: 'AssetTypeTrustAccount',
    CashOnHand: 'AssetTypeCashOnHand',
    PendingNetSaleProceedsFromRealEstateAssets: 'AssetTypePendingNetSaleProceedsFromRealEstateAssets',
    SaleOtherAssets: 'AssetTypeSaleOtherAssets',
    SecuredBorrowedFundsNotDeposited: 'AssetTypeSecuredBorrowedFundsNotDeposited',
    ProceedsFromSecuredLoan: 'AssetTypeProceedsFromSecuredLoan',
    ProceedsFromUnsecuredLoan: 'AssetTypeProceedsFromUnsecuredLoan',
    CashGift: 'AssetTypeCashGift',
    GiftOfEquity: 'AssetTypeGiftOfEquity',
    Grant: 'AssetTypeGrant',
    ProceedsFromSaleOfNonRealEstateAsset: 'AssetTypeProceedsFromSaleOfNonRealEstateAsset',
    Annuity: 'AssetTypeAnnuity',
    Boat: 'AssetTypeBoat',
    BorrowerEstimatedTotalAssets: 'AssetTypeBorrowerEstimatedTotalAssets',
    BorrowerPrimaryHome: 'AssetTypeBorrowerPrimaryHome',
    EmployerAssistance: 'AssetTypeEmployerAssistance',
    EarnestMoneyCashDepositTowardPurchase: 'AssetTypeEarnestMoneyCashDepositTowardPurchase',
    RealEstateOwned: 'AssetTypeRealEstateOwned',
    RecreationalVehicle: 'AssetTypeRecreationalVehicle',
    SavingsBond: 'AssetTypeSavingsBond',
    SeverancePackage: 'AssetTypeSeverancePackage',
    GiftsNotDeposited: 'AssetTypeGiftsNotDeposited',
    GiftsTotal: 'AssetTypeGiftsTotal',
  };

  ApplicationTakenMethodType = {
    FaceToFace: 'ApplicationTakenMethodTypeFaceToFace',
  };

  ResidencyType = {
    PresentAddress: 'ResidencyTypePresentAddress',
    FormerAddress: 'ResidencyTypeFormerAddress',
    MailingAddress: 'ResidencyTypeMailingAddress',
  };

  LiabilityType = {
    Alimony: 'LiabilityTypeAlimony',
    ChildCare: 'LiabilityTypeChildCare',
    SeparateMaintenanceExpense: 'LiabilityTypeSeparateMaintenanceExpense',
    JobRelatedExpenses: 'LiabilityTypeJobRelatedExpenses',
    Revolving: 'LiabilityTypeRevolving',
    HELOC: 'LiabilityTypeHELOC',
    Installment: 'LiabilityTypeInstallment',
    Taxes: 'LiabilityTypeTaxes',
    CollectionsJudgementsAndLiens: 'LiabilityTypeCollectionsJudgementsAndLiens',
    LeasePayments: 'LiabilityTypeLeasePayments',
    MortgageLoan: 'LiabilityTypeMortgageLoan',
    Open30DayChargeAccount: 'LiabilityTypeOpen30DayChargeAccount',
    OtherLiability: 'LiabilityTypeOtherLiability',
    OtherExpense: 'LiabilityTypeOtherExpense',
    TaxLien: 'LiabilityTypeTaxLien',
    ChildSupport: 'LiabilityTypeChildSupport',
    CarMaintenance: 'LiabilityTypeCarMaintenance',
    CharitableContributions: 'LiabilityTypeCharitableContributions',
    Clothing: 'LiabilityTypeClothing',
    DryCleaning: 'LiabilityTypeDryCleaning',
    Entertainment: 'LiabilityTypeEntertainment',
    GroceryToiletry: 'LiabilityTypeGroceryToiletry',
    HealthInsurance: 'LiabilityTypeHealthInsurance',
    Medical: 'LiabilityTypeMedical',
    MiscellaneousLivingExpenses: 'LiabilityTypeMiscellaneousLivingExpenses',
    NetRentalExpense: 'LiabilityTypeNetRentalExpense',
    PayrollInsuranceDeduction: 'LiabilityTypePayrollInsuranceDeduction',
    PayrollMiscellaneousDeductions:
      'LiabilityTypePayrollMiscellaneousDeductions',
    PayrollProfitSharingDeduction: 'LiabilityTypePayrollProfitSharingDeduction',
    PayrollRetirementDeduction: 'LiabilityTypePayrollRetirementDeduction',
    PayrollTaxDeduction: 'LiabilityTypePayrollTaxDeduction',
    UnionDues: 'LiabilityTypeUnionDues',
  };

  IncomeType = {
    Base: 'IncomeTypeBase',
    Bonus: 'IncomeTypeBonus',
    Commissions: 'IncomeTypeCommissions',
    Overtime: 'IncomeTypeOvertime',
    MilitaryCombatPay: 'IncomeTypeMilitaryCombatPay',
    MilitaryFlightPay: 'IncomeTypeMilitaryFlightPay',
    MilitaryHazardPay: 'IncomeTypeMilitaryHazardPay',
    MilitaryOverseasPay: 'IncomeTypeMilitaryOverseasPay',
    MilitaryPropPay: 'IncomeTypeMilitaryPropPay',
    MilitaryClothesAllowance: 'IncomeTypeMilitaryClothesAllowance',
    MilitaryRationsAllowance: 'IncomeTypeMilitaryRationsAllowance',
    MilitaryVariableHousingAllowance: 'IncomeTypeMilitaryVariableHousingAllowance',
    MilitaryQuartersAllowance: 'IncomeTypeMilitaryQuartersAllowance',
    OtherTypesOfIncome: 'IncomeTypeOtherTypesOfIncome'
  };

  EmploymentType = {
    CurrentEmployer: 'EmploymentTypeCurrentEmployer',
    FormerEmployer: 'EmploymentTypeFormerEmployer',
  };

  PropertyTitleType = {
    ToBeDecidedInEscrow: 'PropertyTitleTypeToBeDecidedInEscrow',
  };

  PayoffType = {
    None: 'PayoffTypeNone',
    Partial: 'PayoffTypePartial',
    Full: 'PayoffTypeFull',
  };

  RaceDesignation = {
    AsianIndian: 'RaceDesignationAsianIndian',
    Chinese: 'RaceDesignationChinese',
    Filipino: 'RaceDesignationFilipino',
    GuamanianOrChamorro: 'RaceDesignationGuamanianOrChamorro',
    Japanese: 'RaceDesignationJapanese',
    Korean: 'RaceDesignationKorean',
    NativeHawaiian: 'RaceDesignationNativeHawaiian',
    Samoan: 'RaceDesignationSamoan',
    Vietnamese: 'RaceDesignationVietnamese',
    OtherAsian: 'RaceDesignationOtherAsian',
    OtherPacificIslander: 'RaceDesignationOtherPacificIslander',
  };

  PurchaseCreditType = {
    EarnestMoney: 'PurchaseCreditTypeEarnestMoney',
    DepositOnSalesContract: 'PurchaseCreditTypeDepositOnSalesContract',
    EmployerAssistedHousing: 'PurchaseCreditTypeEmployerAssistedHousing',
    LotEquity: 'PurchaseCreditTypeLotEquity',
    RelocationFunds: 'PurchaseCreditTypeRelocationFunds',
    LeasePurchaseFund: 'PurchaseCreditTypeLeasePurchaseFund',
    TradeEquity: 'PurchaseCreditTypeTradeEquity',
    Other: 'PurchaseCreditTypeOther',
    LenderCredit: 'PurchaseCreditTypeLenderCredit',
    SweatEquity: 'PurchaseCreditTypeSweatEquity',
    SellerCredit: 'PurchaseCreditTypeSellerCredit',
    MIPremiumRefund: 'PurchaseCreditTypeMIPremiumRefund',
  };

  FeePercentOfFieldTypes = {
    LoanAmount: 'LoanAmount',
    TotalLoanAmount: 'TotalLoanAmount',
  };

  MaritalStatusType = {
    Married: 'MaritalStatusTypeMarried',
    Separated: 'MaritalStatusTypeSeparated',
    Single: 'MaritalStatusTypeSingle',
  };

  MilitaryStatusType = {
    isActiveDutyMilitary: 'isActiveDutyMilitary',
    onlyNonActivatedReserveOrNationalGuard:
      'onlyNonActivatedReserveOrNationalGuard',
    isRetiredDischargedSeparatedFromMilitary:
      'isRetiredDischargedSeparatedFromMilitary',
  };

  ProjectType = {
    Condominium: 'ProjectTypeCondominium',
    Cooperative: 'ProjectTypeCooperative',
    PlannedUnitDevelopment: 'ProjectTypePlannedUnitDevelopment',
    NotInAProject: 'ProjectTypeNotInAProject',
  };

  CommunityLendingProductType = {
    HFAPreferred: 'CommunityLendingProductTypeHFAPreferred',
    HFAPreferredRiskSharing:
      'CommunityLendingProductTypeHFAPreferredRiskSharing',
    HomeReady: 'CommunityLendingProductTypeHomeReady',
  };

  CommunitySecondsRepaymentType = {
    DeferredFullyForgiven: 'CommunityLendingProductTypeDeferredFullyForgiven',
    DeferredNotFullyForgiven:
      'CommunityLendingProductTypeDeferredNotFullyForgiven',
    NonDeferred: 'CommunityLendingProductTypeNonDeferred',
  };

  NegativeAmortizationType = {
    NoNegativeAmortization: 'NegativeAmortizationTypeNoNegativeAmortization',
    PotentialNegativeAmortization:
      'NegativeAmortizationTypePotentialNegativeAmortization',
    ScheduledNegativeAmortization:
      'NegativeAmortizationTypeScheduledNegativeAmortization',
  };

  LienPositionType = {
    FirstLien: 'LienPositionTypeFirstLien',
    SecondLien: 'LienPositionTypeSecondLien',
    Other: 'LienPositionTypeOther',
    HELOC: 'LienPositionTypeHELOC',
  };

  ArmIndexSourceType = {
    EleventhDistrictCOF: 'ArmIndexSourceTypeEleventhDistrictCOF',
    ThirtyDayAverageSOFR: 'ArmIndexSourceTypeThirtyDayAverageSOFR',
    DailyCDRate: 'ArmIndexSourceTypeDailyCDRate',
    FannieMae60DayRequiredNetYield:
      'ArmIndexSourceTypeFannieMae60DayRequiredNetYield',
    FannieMaeLIBOR: 'ArmIndexSourceTypeFannieMaeLIBOR',
    FederalCostOfFunds: 'ArmIndexSourceTypeFederalCostOfFunds',
    FreddieMac60DayRequiredNetYield:
      'ArmIndexSourceTypeFreddieMac60DayRequiredNetYield',
    FreddieMacLIBOR: 'ArmIndexSourceTypeFreddieMacLIBOR',
    MonthlyAverageCMT: 'ArmIndexSourceTypeMonthlyAverageCMT',
    NationalAverageContractRate:
      'ArmIndexSourceTypeNationalAverageContractRate',
    NationalMonthlyMedianCostOfFunds:
      'ArmIndexSourceTypeNationalMonthlyMedianCostOfFunds',
    TBillDailyValue: 'ArmIndexSourceTypeTBillDailyValue',
    WallStreetJournalLIBOR: 'ArmIndexSourceTypeWallStreetJournalLIBOR',
    WeeklyAvePrimeRate: 'ArmIndexSourceTypeWeeklyAvePrimeRate',
    WeeklyAverageCDRate: 'ArmIndexSourceTypeWeeklyAverageCDRate',
    WeeklyAverageCMT: 'ArmIndexSourceTypeWeeklyAverageCMT',
    WeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15:
      'ArmIndexSourceTypeWeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15',
    WeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15:
      'ArmIndexSourceTypeWeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15',
    WeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15:
      'ArmIndexSourceTypeWeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15',
  };

  LandValueType = {
    Appraised: 'LandValueTypeAppraised',
    Original: 'LandValueTypeOriginal',
  };

  PropertyWillBeType = {
    PrimaryResidence: 'PropertyWillBeTypePrimaryResidence',
    SecondaryResidence: 'PropertyWillBeTypeSecondaryResidence',
    Investment: 'PropertyWillBeTypeInvestment',
  };
}

export class Constants {
  public static editToken: string = "editToken";
  public static authToken: string = "authToken";
  public static companyGuid: string = "companyGuid";
  public static userCompanyGuid: string = "userCompanyGuid";
  public static branchGuid: string = "branchGuid";
  public static returnUrl: string = "returnUrl";
  public static userName: string = "username";
  public static token: string = "token";
  public static clientIpKey: string = "clientIpAddress";
  public static userGuid: string = "userGuid";
  public static flowGuid: string = "flowGuid";
  public static applicationId: string = "appId";
  public static contactId: string = "contactId";
  public static isCoborrower: string = "isCoborrower";

  public static flowEndpoints: FlowEndpoints = new FlowEndpoints();
  public static stepPaths: StepPaths = new StepPaths();
  public static assetVerificationOptions: AssetVerificationOptions = new AssetVerificationOptions();
  public static incomeVerificationOptions: IncomeVerificationOptions = new IncomeVerificationOptions();

  public static authorization: Authorization = new Authorization();

  public static modalOptions: ModalOptions = new ModalOptions();

  public static thirdPartyTrackingScripts = new ThirdPartyTrackingScripts();

  public static mimeTypes = new MimeTypes();

  public static enumerationValueNames = new EnumerationValueNames();

  public static dirtyCheckIgnoreFieldsMetaDataField: string = "fields_to_ignore_for_dirty_check";

  public static mortgageEnumerations: MortgageEnumerations =
    new MortgageEnumerations();
}
