import { Injectable, Injector } from "@angular/core";
import { IProvideOptions } from "./options-provider.interface";
import { LoanPurposeOptionsProvider } from "./loan-purpose-options-provider";

@Injectable()
export class OptionsProviderFactory {

  constructor(private readonly _injector: Injector) {
  }

  create = (providerType: OptionsProviderTypes): IProvideOptions | null => {
    switch(providerType) {
      case OptionsProviderTypes.LoanPurpose:
        const loanPurposeProvider = this._injector.get(LoanPurposeOptionsProvider);
        return loanPurposeProvider;
      default:
        return null;
    }
  }
}

export enum OptionsProviderTypes {
  LoanPurpose = "LoanPurpose"
}
